.main {
  min-height: calc(100vh - 80px);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  font-weight: regular;
}

.logo {
  top: 30px;
}

.formGroup {
  margin-top: 15px;
  margin-bottom: 10px;

  font-size: 18px;
}
.formGroup label {
  display: block;
  padding-bottom: 2px;
}
.formGroup input {
  border: 2px solid black;
  color: #333;
  /* background-color: #efefef;  */
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 15px;
}
.buttonContainer {
  width: 100%;
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.buttonContainer button {
  width: 100%;
}

.footer {
  height: 62px;
  position: sticky;
  bottom: 0px;
  right:0px;

  min-width: 100vh;
  padding: 20px;  

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 37.5%;
  width: 25%;
  text-align: center;
  height: 2px;
  background-color: #eb4a27;
}
.footer div {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.footer a {
  display: block;

}
